import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { message } from "antd";

import { upLoadFile } from "../api";

window.tinymce.baseURL = "//topway-npm.oss-cn-shanghai.aliyuncs.com/tinymce-5.5.1";

const fontSizeList = (start=10,end=36)=>{
  const list =[]
  for (let index = 10; index <= 36; index++) {
    list.push(index)
  }
  return list.map(size=>`${size}px`).join(' ')
}

export default class RichTextInput extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      return true;
    }
    return false;
  }

  onEditorChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  imagesUploadHandler = (blobInfo, success, failure, progress) => {
    upLoadFile(blobInfo.blob(), null, ({ total, loaded, lengthComputable }) => {
      if (lengthComputable) {
        progress(Math.round((loaded / total) * 100));
      }
    })
      .then(({ data }) => {
        success(data);
      })
      .catch(failure);
  };

  render() {
    const height = this.props.height || 400;
    const value = this.props.value || "";

    return (
      <div style={{ minHeight: height }}>
        <Editor
          value={value}
          onEditorChange={this.onEditorChange}
          init={{
            relative_urls: false,
            image_advtab: true,
            height,
            menubar: false,
            inline: this.props.inline || false,
            fontsize_formats: fontSizeList(),
            plugins:
              "image table paste advlist lists fullscreen code media imagetools quickbars link",
            language_url: "/tinymce/langs/zh_CN.js",
            language: "zh_CN",
            contextmenu: "link image imagetools table configurepermanentpen",
            paste_as_text: true,
            images_upload_handler: this.imagesUploadHandler,
            file_picker_callback: function (callback, value, meta) {
              let accept = "";
              if (meta.filetype === "image") {
                accept = "image/*";
              }
              if (meta.filetype === "media") {
                accept = "video/*";
              }
              if (!accept) {
                message.warn(`不支持的类型[${meta.filetype}]！`);
                return;
              }

              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", accept);
              input.onchange = function () {
                const file = this.files[0];
                const key = `updatable-${file.lastModified}-${Date.now()}`;
                message.loading({ content: '上传中', key });               

                upLoadFile(
                  file,
                  null,
                  ({ total, loaded, lengthComputable }) => {
                    if (lengthComputable) {
                      const jj = Math.round((loaded / total) * 100);
                      message.loading({ content: `上传进度${jj}%`, key });                      
                    }
                  }
                ).then(({ data }) => {
                  message.success({ content: `上传完成`, key});
                  callback(data);
                }).catch(error=>{
                  message.error(error?.response?.data)
                });
              };

              input.click();
            },
            toolbar:
              "undo redo | formatselect fontsizeselect  bold italic forecolor backcolor link | alignleft aligncenter alignright | table image media | numlist bullist | fullscreen code",
          }}
        />
      </div>
    );
  }
}
