import React from "react";
import { Tabs, Alert } from "antd";

// import ActivitySetting from "./ActivitySetting";
//import CompetitionActivitySetting from "./CompetitionActivitySetting";
import CarouselSetting from "./CarouselSetting";
// import HRProductSetting from './HRProductSetting'
// import OurCustomerSetting from './OurCustomerSetting'

const { TabPane } = Tabs;

export default () => {
  return (
    <div style={{ padding: 32 }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="轮播图" key="1">
          <Alert
            message={<>请使用 <strong>深色</strong>、尺寸为<strong> 1903*620 </strong>的图片，来达到最好展示效果</>}
            type="info"
            style={{ marginBottom: 16 }}
          />
          <CarouselSetting />
        </TabPane>
        {/* <TabPane tab="人力资源比赛动态" key="5">
          <CompetitionActivitySetting />
        </TabPane> */}
        {/* <TabPane tab="踏瑞官方活动" key="2">
          <ActivitySetting />
        </TabPane> */}
        {/* <TabPane tab="人力资源教学产品" key="3">
          <HRProductSetting />
        </TabPane> */}
        {/* <TabPane tab="我们的客户" key="4">
          <OurCustomerSetting />
        </TabPane> */}
      </Tabs>
    </div>
  );
};
