import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Spin } from "antd";

import Upload from "../../components/Upload";
import Editor from "../../components/Editor";
import ImportContent from "../../components/ImportContent";
import { getArticleByRefId, insertArticleByRefId } from "../../api";
import { getDescription } from "../../tools";

export default ({ refId = "" , noCover,coverRequired=true}) => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const [form] = Form.useForm();
  const save = async () => {
    const formData = await form.validateFields();
    await insertArticleByRefId(refId, {
      ...formData,
      id: article.id,
      createAt: article.createAt,
      description: getDescription(formData.content),
    })
      .then(({ data }) => {
        message.success("保存成功！");

        form.setFieldsValue(data || {});
      })
      .catch(() => {
        message.error("保存失败！");
      });
  };

  useEffect(() => {
    if (!refId) return;
    setLoading(true);
    getArticleByRefId(refId)
      .then(({ data }) => {
        if (data) {
          form.setFieldsValue(data);
          setArticle(data);
        } else {
          setArticle({});
          form.resetFields();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refId, form]);

  return (
    <div>
      <Spin spinning={loading} delay={500}>
        <Form form={form} autoComplete="off" scrollToFirstError={true}>
          <Form.Item
            label="标题"
            name="title"
            rules={[{ required: true, message: "请输入标题！" }]}
          >
            <Input />
          </Form.Item>
          {!noCover && <Form.Item
            label="封面"
            name="cover"
            extra="建议尺寸 825*404"
            rules={[{ required: coverRequired, message: "请上传封面！" }]}
          >
            <Upload />
          </Form.Item>}
          <Form.Item
            label="正文"
            name="content"
            rules={[{ required: true, message: "请输入正文！" }]}
          >
            <Editor />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Button
              onClick={save}
              type="primary"
              style={{ marginRight: "1em" }}
            >
              保存
            </Button>
            <ImportContent
              onOk={(html) => form.setFieldsValue({ content: html })}
            />
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
