import React from "react";
import { Result } from "antd";

export default () => {
  return (
    <Result
      status={500}
      title="500"
      subTitle="Sorry, something went wrong."
    ></Result>
  );
};
