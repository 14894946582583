import React from "react";
import { Tabs } from "antd";

import Articles from "./Articles";
import ProductPage from "../category/ProductPage";
import MatchCarouse from "./MatchCarouse"

const { TabPane } = Tabs;

const getRefIds = (type = "") => {
  if (type.toLocaleLowerCase() === "college") {
    return "000000000000000000000005";
  } else if (type.toLocaleLowerCase() === "undergraduates") {
    return "000000000000000000000006";
  } else {
    return "";
  }
};

export default ({ match }) => {
  return (
    <div style={{ padding: 32 }}>
      <h2>{match.params.type === "college" ? "专科" : "本科"}</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="比赛轮播图" key="1">
          <MatchCarouse type={match.params.type} />
        </TabPane>
        <TabPane tab="赛事动态" key="2">
          <Articles type={match.params.type} />
        </TabPane>
        <TabPane tab="比赛规则" key="3">
          <ProductPage refId={getRefIds(match.params.type)} noCover />
        </TabPane>
      </Tabs>
    </div>
  );
};
