import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { useKeyPress } from "ahooks";
import styled from "styled-components";

import { login } from "../../api/index";

const Page = styled.div`
  height: 100vh;
`;
const Wrap = styled.div`
  width: 400px;
  left: 50%;
  top: 40%;
  position: relative;
  transform: translate(-50%, -50%);
`;

export default ({ history }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");

  const click = () => {
    form.validateFields().then(({ username, password }) => {
      login(username, password)
        .then(() => {
          history.push("/");
        })
        .catch(({ response }) => {
          setError(response.data);
        });
    });
  };

  useKeyPress("enter", click);

  return (
    <Page>
      <Wrap>
        {error && <Alert message={error} type="error" />}
        <Form form={form} layout="vertical">
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: "请输入用户名" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" block onClick={click}>
            登陆
          </Button>
        </Form>
      </Wrap>
    </Page>
  );
};
