import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Divider,
  Alert,
  Dropdown,
  Menu,
} from "antd";

import { useBoolean } from "ahooks";
import { getHtmlByUrl } from "../api";

const topwaysoftXpath =
  "//div[@class='container content-sm']/div[@class='row']/div[@class='col-md-12']";

const getXPathByUrl = (url) => {
  if (/^https?:\/\/www.topwaysoft.com\/product\/item_bak/i.test(url)) {
    return topwaysoftXpath;
  }
  if (/^https?:\/\/www.topwaysoft.com\/new\/detail/i.test(url)) {
    return "//div[@class='container content-sm']/div[@class='row']/div[@class='col-md-9']/div[@class='row']/div[@class='col-md-12']";
  }
  if (/^https?:\/\/www.topwaysoft.com\/solution\/index/i.test(url)) {
    return "//div[@class='container content-sm']/div[@class='row']/div[@class='col-md-9']";
  }
  if (/^https?:\/\/mp.weixin.qq.com\/s/i.test(url)) {
    return "//div[@id='js_content']";
  }
  return "";
};

const getClipboardText = async () => {
  const clipboardItems = await navigator.clipboard.read();
  for (const clipboardItem of clipboardItems) {
    for (const type of clipboardItem.types) {
      if (type.includes("text")) {
        const blob = await clipboardItem.getType(type);
        const text = await blob.text();
        return text;
      }
    }
  }
};

const ImportContent = ({ onOk }) => {
  const [visible, { setFalse, setTrue }] = useBoolean();
  const [html, setHtml] = useState("");
  const okClick = () => {
    setFalse();
    onOk(html);
  };
  const onOpen = () => {
    setTrue();
    setHtml("输入网址，点击获取");
  };
  const onSubmit = (value) => {
    if (!getXPathByUrl(value.url)) {
      message.warn("不支持当前网址！");
      return;
    }
    getHtmlByUrl({
      xpath: getXPathByUrl(value.url),
      url: value.url,
    }).then(({ data }) => setHtml(data));
  };

  const oneBtnClick = async () => {
    try {
      const text = await getClipboardText();
      const xpath = getXPathByUrl(text);
      if (!!xpath) {
        getHtmlByUrl({
          xpath: xpath,
          url: text,
        }).then(({ data }) => onOk(data));
      }else{
        message.warn(`网址:${text},不符合要求!请点击[从网址获取]查看详情`)
      }
    } catch (error) {
      message.error("获取失败")
    }
  };

  return (
    <span>
      <Dropdown.Button
        onClick={onOpen}
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={oneBtnClick}>
              从粘贴板的网址获取
            </Menu.Item>
          </Menu>
        }
      >
        从网址获取
      </Dropdown.Button>
      <Modal
        onCancel={setFalse}
        onOk={okClick}
        visible={visible}
        title="从网址获取正文"
        width={765}
        bodyStyle={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}
      >
        <Alert
          style={{ marginBottom: "2em" }}
          message={
            <div>
              当前<strong>从网址获取</strong>功能支持的网址有限。分别是：
              <br />
              踏瑞原官网-解决方案详情页：
              <code>http://www.topwaysoft.com/Solution/Index/xxx</code>
              <br />
              踏瑞原官网-产品详情页：
              <code>http://www.topwaysoft.com/Product/Item_bak/xxx</code>
              <br />
              踏瑞原官网-新闻详情页：
              <code>http://www.topwaysoft.com/New/Detail/xxx</code>
              <br />
              微信公众号：<code>https://mp.weixin.qq.com/sxxx</code>
            </div>
          }
          type="warning"
        />
        <Form onFinish={onSubmit}>
          <Input.Group compact>
            <Form.Item noStyle name="url">
              <Input
                style={{ width: "calc(100% - 179px)" }}
                placeholder="请输入网址"
              />
            </Form.Item>
            <Button type="primary" style={{ width: 64 }} htmlType="submit">
              获取
            </Button>
          </Input.Group>
        </Form>
        <Divider plain>获取的内容</Divider>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </Modal>
    </span>
  );
};

export default ImportContent;
