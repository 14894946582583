import React, { useRef, useState } from "react";
import { Button, message, Image} from "antd";
import { FileAddTwoTone } from "@ant-design/icons";

import { upLoadFile } from "../api";

const defaultStyle = {};

export default ({
  value,
  onChange,
  accept,
  height = 200,
  width = 300,
  style = defaultStyle,
}) => {
  const inputEl = useRef(null);
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    inputEl.current.value = "";
    inputEl.current.click();
  };
  const onFileChange = async (value) => {
    const file = value.target.files[0];
    if(file.size > 5242880){
      message.warn("请上传小于 5M 的图片！")
      return
    }
    setLoading(true);
    try {
      const { data } = await upLoadFile(file);
      onChange(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getClipboardContents = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          if (type.includes("image")) {
            const blob = await clipboardItem.getType(type);
            const files = [
              new File([blob], `.${type.split("/")[1]}`, { type: type }),
            ];
            onFileChange({ target: { files: files } });
            return;
          }
        }
      }
      message.warn("当前粘贴板没有图片！");
    } catch (err) {
      console.log(err);
      message.success("上传图片失败！");
    }
  };

  return (
    <div className="upload-commponent">
      <div style={{ marginBottom: 8 }}>
        <Button loading={loading} onClick={onClick}>
          <FileAddTwoTone />
          上传文件
          <input
            onChange={onFileChange}
            type="file"
            ref={inputEl}
            accept={accept}
            style={{ display: "none" }}
          />
        </Button>
        <Button
          className="clipboard-upload"
          type="text"
          onClick={getClipboardContents}
        >
          上传粘贴的图片
        </Button>
      </div>
      <div>
        {value && (
          <Image
            style={style}
            src={value}
            alt="upload"
            height={height}
            width={width}
          />
        )}
      </div>
    </div>
  );
};
