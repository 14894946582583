import React from "react";
import {
  Table,
  Modal,
  Form,
  Input,
  Button,
  InputNumber,
  message,
  Tag,
} from "antd";
import { useBoolean } from "ahooks";
import { PushpinOutlined } from "@ant-design/icons";

import { dateFormat, getDescription } from "../../tools";
import Upload from "../../components/Upload";
import Editor from "../../components/Editor";
import DateTimePicker from "../../components/DateTimePicker";
import ImportContent from "../../components/ImportContent";

import {
  getMatchArticleListByLevel,
  createMatchArticle,
  updateMatchArticle,
  deleteMatchArticleById,
  pinMatchArticle,
  delPinMatchArticle,
} from "../../api";

const columns = [
  {
    title: "名称",
    dataIndex: "title",
    ellipsis: true,
    width: "50%",
  },
  {
    title: "置顶",
    dataIndex: "pin",
    render: (pin) => (!!pin ? "是" : "否"),
    width: "5%",
  },
  {
    title: "比赛年份",
    dataIndex: "period",
    render: (period) => <Tag color="#108ee9">{period}</Tag>,
    width: "8%",
  },
  {
    title: "发布时间",
    dataIndex: "publishDate",
    render: dateFormat,
  },
  {
    title: "更新时间",
    dataIndex: "updateAt",
    render: dateFormat,
  },
  {
    title: "操作",
    width: "20%",
    render: (row) => {
      return (
        <>
          <Button
            data-action="置顶"
            style={{ marginRight: "1em" }}
            icon={<PushpinOutlined />}
          >
            {`${row.pin ? "取消" : ""}`}置顶
          </Button>
          <Button data-action="编辑" style={{ marginRight: "1em" }}>
            编辑
          </Button>
          <Button data-action="删除" danger>删除</Button>
        </>
      );
    },
  },
];

export default ({ type }) => {
  const [searchValue, setSearchValue] = React.useState();
  const [pageModel, setPageModel] = React.useState({
    content: [],
    count: 0,
    currentPage: 0,
    size: 0,
  });
  const onPageChange = React.useCallback(
    (page, size, searchValue) => {
      getMatchArticleListByLevel(type, page, size, searchValue).then(
        ({ data }) => {
          setPageModel(data);
        }
      );
    },
    [type]
  );
  React.useEffect(() => {
    onPageChange(1, 10, searchValue);
  }, [onPageChange, searchValue]);

  const [visible, { setFalse: close, setTrue: open }] = useBoolean();
  const [form] = Form.useForm();
  const onNewBtnClick = () => {
    form.resetFields();
    form.setFieldsValue({
      level: type,
      type: "Article",
      period: new Date().getFullYear(),
    });
    open();
  };

  const onEditBtnClick = React.useCallback(
    (data) => {
      form.setFieldsValue(data);
      open();
    },
    [form, open]
  );
  const onSave = async () => {
    const formValues = await form.validateFields();
    if (formValues.id) {
      updateMatchArticle({
        ...formValues,
        description:
          formValues.description || getDescription(formValues.content),
      });
      message.success("编辑成功！");
    } else {
      createMatchArticle({
        ...formValues,
        description:
          formValues.description || getDescription(formValues.content),
      });
      message.success("新增成功！");
    }
    onPageChange(pageModel.currentPage, pageModel.size);
    close();
  };
  const onRowClick = React.useCallback(
    (record) => {
      return {
        onClick: (event) => {
          if(event.target.tagName !== "SPAN"){
            return
          }
          if (event.target.innerText === "编 辑") {
            onEditBtnClick(record);
          }
          if (event.target.innerText === "删 除") {
            Modal.confirm({
              title: "提示！",
              content: "确认要删除吗？",
              onOk: () =>
                deleteMatchArticleById(record.id).then(() => {
                  onPageChange(pageModel.currentPage, pageModel.size);
                }),
            });
          }
          if (event.target.innerText.indexOf("置顶") >= 0) {
            Modal.confirm({
              title: "提示！",
              content: record.pin
                ? "此项会被取消置顶"
                : "此项会被置顶到第一个位置",
              onOk: () => {
                if (record.pin) {
                  delPinMatchArticle(record.id).then(() => {
                    onPageChange(pageModel.currentPage, pageModel.size);
                  });
                } else {
                  pinMatchArticle(record.id).then(() => {
                    onPageChange(pageModel.currentPage, pageModel.size);
                  });
                }
              },
            });
          }
        },
      };
    },
    [onEditBtnClick, onPageChange, pageModel]
  );

  return (
    <div>
      <div style={{ marginBottom: "1em" }}>
        <Button onClick={onNewBtnClick} type="primary">
          新增动态
        </Button>
      </div>
      <div className="search-form">
        <Input.Search
          allowClear
          placeholder="搜索名称"
          style={{ width: 300 }}
          onSearch={setSearchValue}
        />
      </div>
      <Table
        rowKey={(t) => t.id}
        tableLayout="fixed"
        columns={columns}
        dataSource={pageModel.content}
        pagination={{
          current: pageModel.currentPage,
          pageSize: pageModel.size,
          total: pageModel.count,
          onChange: onPageChange,
        }}
        onRow={onRowClick}
      />
      <Modal
        maskClosable={false}
        visible={visible}
        width={1000}
        okText="保存"
        onCancel={close}
        title="新增/编辑动态"
        onOk={onSave}
      >
        <Form layout="vertical" form={form} autoComplete="off">
          <Form.Item label="id" name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="level" name="level" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="type" name="type" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="type" name="createAt" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="标题"
            name="title"
            rules={[{ required: true, message: "请输入标题！" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="发布日期"
            name="publishDate"
            rules={[{ required: true, message: "选择时间" }]}
          >
            <DateTimePicker
              showTime
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
          <Form.Item
            label="比赛年份"
            name="period"
            rules={[
              { required: true, message: "请输入年份" },
              {
                max: new Date().getFullYear() + 10,
                min: 2010,
                type: "number",
                message: "年份范围在 2010 至本年延后 10 年",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="封面" name="cover" extra="建议尺寸 825*404">
            <Upload />
          </Form.Item>
          <Form.Item
            label="概要"
            name="description"
            help={"如果不输入概要,会自动从正文中获得概要"}
            extra={
              <Button
                type="link"
                onClick={() => {
                  const description = getDescription(
                    form.getFieldValue("content")
                  );
                  form.setFieldsValue({ description });
                }}
              >
                手动从正文获得概要
              </Button>
            }
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="正文"
            name="content"
            rules={[{ required: true, message: "请输入正文！" }]}
          >
            <Editor />
          </Form.Item>
        </Form>
        <ImportContent
          onOk={(html) => form.setFieldsValue({ content: html })}
        />
      </Modal>
    </div>
  );
};
