import axios from 'axios'
import qs from 'qs'

export const AxiosRequest = axios.create({
    baseURL: "/api",
})

export const login = (username, password) => {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    return AxiosRequest.post('/users/login', { userName: username, password: password })
}

export const logout = () => {
    return AxiosRequest.get('/users/logout')
}

export const ping = () => AxiosRequest.get("/users/ping")

export const getProduct = () => AxiosRequest.get("/product")

export const getProductById = (id) => AxiosRequest.get(`/product/${id}`)

export const getArticleByRefId = (id) => AxiosRequest.get(`/article/ref/${id}`)

export const getArticleById = (id) => AxiosRequest.get(`/article/${id}`)

export const getArticleByRefIds = (ids) => AxiosRequest.get(`/article/refs?${ids.map(id => `ids=${id}`).join('&')}`)

export const deleteArticleById = (id) => AxiosRequest.delete(`/article/${id}`)

export const createArticle = (data) => AxiosRequest.post(`/article`, data)

export const updateArticleById = (id, data) => AxiosRequest.put(`/article/${id}`, data)

export const getArticleListByRefId = (id, page = 1, size = 10,searchValue) => AxiosRequest.get(`/article/ref/${id}/list`, { params: { page, size,name:searchValue } })

export const getArticleListByRefIds = (ids, page = 1, size = 10,searchValue) => {
    return AxiosRequest.get(`/article/ref/list`, { params: { page, size,name:searchValue ,ids} ,paramsSerializer:(p)=>{
            return qs.stringify(p,{arrayFormat:'repeat'})
    }})
}

export const insertArticleByRefId = (refId, data) => AxiosRequest.put(`/article/ref/${refId}`, data)

export const createProduct = (data) => AxiosRequest.post(`/product`, data)

export const deleteProduct = (id) => AxiosRequest.delete(`/product/${id}`)

export const saveProductById = (id, data) => AxiosRequest.put(`/product/${id}`, data)

//首页接口
export const getHomeDataCarouselSection = () => AxiosRequest.get(`/homedata/carousel-section`)

export const getHomeDataActivitySection = () => AxiosRequest.get(`/homedata/activity-section`)

export const setCarouselSection = (data) => AxiosRequest.put(`/homedata/carousel-section`, data)

export const setActivitySection = (data) => AxiosRequest.put(`/homedata/activity-section`, data)

export const getHRSection = () => AxiosRequest.get(`/homedata/hr-product-section`)
export const saveHRSection = (data) => AxiosRequest.put(`/homedata/hr-product-section`, data)

export const getCustomerImgSection = () => AxiosRequest.get(`/homedata/customer-image-section`)
export const saveCustomerImgSection = (data) => AxiosRequest.put(`/homedata/customer-image-section`, data)

export const getCompetitionActivitySection = () => AxiosRequest.get(`/homedata/competition-activity-section`)
export const setCompetitionActivitySection = (data) => AxiosRequest.put(`/homedata/competition-activity-section`, data)
//end 首页接口

// 比赛动态接口
//MatchArticle
export const getMatchArticleListByPage = (page = 1, size = 10) => AxiosRequest.get(`/MatchArticle`, { params: { page, size } })
export const getMatchArticleListByLevel = (level, page = 1, size = 10,searchValue) => AxiosRequest.get(`/MatchArticle/level/${level}`, { params: { page, size,name:searchValue } })
export const createMatchArticle = (data) => AxiosRequest.post(`/MatchArticle`, data)
export const updateMatchArticle = (data) => AxiosRequest.put(`/MatchArticle`, data)
export const deleteMatchArticleById = (id) => AxiosRequest.delete(`/MatchArticle/${id}`)
export const getMatchCarouseByLevel = (lv) => AxiosRequest.get(`/MatchCarouse/${lv}`)
export const saveMatchCarouse = (data) => AxiosRequest.put(`/MatchCarouse`, data)
export const pinMatchArticle = (id) => AxiosRequest.post(`/MatchArticle/${id}/pin`)
export const delPinMatchArticle = (id) => AxiosRequest.delete(`/MatchArticle/${id}/pin`)

export const upLoadFile = (file, cancelToken, onUploadProgress) => {
    const data = new FormData();
    data.append("formFile", file);
    return AxiosRequest.post("/files", data, {
        onUploadProgress: onUploadProgress,
        cancelToken: cancelToken,
    })
}


// 表单接口

export const getFormList = (page = 1, size = 10) => AxiosRequest.get(`/form`, { params: { page, size } })
export const downloadCSV = (params) => {
    window.open(`/api/form/csv?` + qs.stringify(params), "_blank")
}

// 获取html
export const getHtmlByUrl = (params) => AxiosRequest.get(`/HtmlParser`, { params })


