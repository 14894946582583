import React from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Divider,
  Select,
  DatePicker,
  Modal,
  Descriptions,
  Drawer,
  Tag,
} from "antd";
import { useBoolean } from "ahooks";

import { dateFormat } from "../../tools";
import { getFormList, getProductById, downloadCSV } from "../../api";

const Types = {
  0: ["试用申请", "#87d068"],
  1: ["联系我们", "#f50"],
  2: ["报名申请", "#2db7f5"],
  3: ["账号申请", "#108ee9"],
};
const Status = {
  0: "未处理",
  1: "已处理",
};
const columns = [
  {
    title: "学校",
    dataIndex: "school",
    ellipsis: true,
  },
  {
    title: "学院",
    dataIndex: "college",
    ellipsis: true,
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "手机",
    dataIndex: "phone",
  },
  {
    title: "邮箱",
    dataIndex: "email",
  },
  {
    title: "状态",
    dataIndex: "status",
    render: (status) => Status[status],
  },
  {
    title: "类型",
    dataIndex: "type",
    render: (type, row) => {
      return (
        <>
          <Tag color={Types[type][1]}>{Types[type][0]}</Tag>
          {(type === 2 || type === 3) && (
            <Tag>
              {row?.refName?.toLowerCase() === "college" ? "专科" : "本科"}
            </Tag>
          )}
        </>
      );
    },
  },
  {
    title: "提交时间",
    dataIndex: "createAt",
    render: dateFormat,
  },
  {
    title: "操作",
    render: (row) => <Button style={{ marginRight: "1em" }}>详情</Button>,
  },
];

export default () => {
  const [visible, { setFalse: close, setTrue: open }] = useBoolean();
  const [currentRow, setRow] = React.useState({});
  const [pageModel, setPageModel] = React.useState({
    content: [],
    count: 0,
    currentPage: 0,
    size: 0,
  });

  const onPageChange = React.useCallback((page, size) => {
    getFormList(page, size).then(({ data }) => {
      setPageModel(data);
    });
  }, []);

  const onRowClick = React.useCallback(
    (record) => {
      return {
        onClick: (event) => {
          if (event.target.innerText === "详 情") {
            open();
            setRow(record);
          }
        },
      };
    },
    [open]
  );

  React.useEffect(() => {
    onPageChange(1, 10);
  }, [onPageChange]);

  return (
    <div style={{ padding: 32 }}>
      <ExportData />

      <Table
        rowKey={(t) => t.id}
        tableLayout="fixed"
        columns={columns}
        dataSource={pageModel.content}
        pagination={{
          current: pageModel.currentPage,
          pageSize: pageModel.size,
          total: pageModel.count,
          onChange: onPageChange,
        }}
        onRow={onRowClick}
      />
      <Drawer title="详情" width={720} visible={visible} onClose={close}>
        <Descriptions
          bordered
          title={Types[currentRow?.type]?.[0]}
          size="small"
        >
          <Descriptions.Item label="姓名">{currentRow.name}</Descriptions.Item>
          <Descriptions.Item label="手机">{currentRow.phone}</Descriptions.Item>
          <Descriptions.Item label="邮箱">{currentRow.email}</Descriptions.Item>
          <Descriptions.Item label="学校">
            {currentRow.school}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="学院">
            {currentRow.college}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="其他">
            <RefName type={currentRow.type} refName={currentRow.refName} />
          </Descriptions.Item>

          <Descriptions.Item span={3} label="备注/留言">
            {currentRow.remarks}
          </Descriptions.Item>
        </Descriptions>
        <Divider plain>处理</Divider>
        <Form>
          <Form.Item label="回复" name="reply">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const ExportData = () => {
  const [form] = Form.useForm();
  const [visible, { setFalse, setTrue }] = useBoolean();

  const download = (formData) => {
    const params = {
      start: formData.range[0].format("yyyy-MM-DD"),
      end: formData.range[1].format("yyyy-MM-DD"),
      refName: formData.refName,
      type: formData.type,
    };
    downloadCSV(params);
  };

  return (
    <>
      <Button onClick={setTrue}>导出数据</Button>
      <Modal
        onCancel={setFalse}
        maskClosable={false}
        visible={visible}
        title="导出数据"
        onOk={() => {
          form.validateFields().then(download).then(setFalse);
        }}
      >
        <Form form={form}>
          <Form.Item label="范围" name="range" rules={[{ required: true }]}>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label="类型" name="type">
            <Select>
              <Select.Option value={0}>试用申请</Select.Option>
              <Select.Option value={1}>联系我们</Select.Option>
              <Select.Option value={2}>报名申请</Select.Option>
              <Select.Option value={3}>账号申请</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item dependencies={["type"]} noStyle>
            {() =>
              form.getFieldValue("type") > 1 && (
                <Form.Item label="比赛" name="refName">
                  <Select>
                    <Select.Option value={"college"}>本科比赛</Select.Option>
                    <Select.Option value={"undergraduates"}>
                      专科比赛
                    </Select.Option>
                  </Select>
                </Form.Item>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const RefName = ({ type, refName }) => {
  const [name, setName] = React.useState();

  React.useEffect(() => {
    if (type === 0 && refName) {
      getProductById(refName).then(({ data }) => {
        setName(data.name);
      });
    }
  }, [type, refName]);

  if (type === 2 || type === 3) {
    return (
      <span>
        比赛：
        <strong>
          {refName?.toLowerCase() === "college" ? "专科比赛" : "本科比赛"}
        </strong>
      </span>
    );
  }
  if (type === 0) {
    return (
      <span>
        试用产品： <strong>{name}</strong>
      </span>
    );
  }
  return null;
};
