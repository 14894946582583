import React, { useRef, useEffect } from "react";
import { Space, Button, Divider, Form, Input, message, Modal,Switch } from "antd";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
// import get from "lodash.get";

import ProductPage from "./ProductPage";

import { deleteProduct, saveProductById, createProduct } from "../../api/index";
import { useCategory } from "../../components/hooks";

import "./index.less";

// const labelCol = { span: 2 };
// const wrapperCol = { span: 16 };

const Ul = styled.ul`
  list-style: none;
  padding-left: 16px;
`;

const Wrap = styled.div`
  display: flex;
  padding: 16px;
`;

const Right = styled.div`
  flex: 1;
  padding: 16px;
`;
const Left = ({ products, reloadData }) => {
  const add = () => {
    const name = prompt("新增导航", "导航名称");
    if (name) {
      createProduct({ name })
        .then(reloadData)
        .then(() => {
          message.success("新增成功");
        });
    }
  };

  return (
    <div style={{ minWidth: 150 }}>
      <Button type="primary" onClick={add}>
        新增
      </Button>
      <Nodes products={products} />
    </div>
  );
};

const Nodes = ({ products = [] }) => {
  return (
    <Ul>
      {products.map((item) => {
        return (
          <li key={item.id}>
            <NavLink
              className="category-link"
              activeClassName="category-select"
              to={`/navigation/${item.id}`}
            >
              {item.name}
            </NavLink>
            <Nodes products={item.children || []} />
          </li>
        );
      })}
    </Ul>
  );
};

export default ({ match }) => {
  const history = useHistory();
  const { data, fetchData, originalList } = useCategory();
  const modalFormRef = useRef();
  const [form] = Form.useForm();

  const remove = () => {
    const current = originalList.find((item) => item.id === match.params.id);
    Modal.warn({
      title: "提示",
      content: (
        <>
          确认要删除 <strong>{current.name}</strong> 吗？
        </>
      ),
      onOk: async () => {
        await deleteProduct(current.id).then(fetchData);
        history.push("/navigation");
      },
    });
  };
  const saveItem = async () => {
    const formData = await form.validateFields();

    await saveProductById(match.params.id, {
      name: formData.name,
      show:formData.show
    });
    await fetchData();
    message.success("保存成功");
  };

  const addSubItem = async () => {
    const current = originalList.find((item) => item.id === match.params.id);
    Modal.confirm({
      title: "新增",
      content: (
        <Form
          ref={modalFormRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
        >
          <Form.Item
            label="名称"
            name="name"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: () => {
        return modalFormRef.current
          .validateFields()
          .then((data) => {
            return createProduct({
              parentId: current.id,
              ...data,
            });
          })
          .then(() => {
            fetchData();
            message.success("创建成功");
          })
          .catch((error) => {
            message.error(error.response.data.errors.Name.join(","));
            return Promise.reject();
          });
      },
      onCancel: () => {
        modalFormRef.current.resetFields();
      },
    });
  };

  useEffect(() => {
    const current = originalList.find((item) => item.id === match.params.id);
    form.setFieldsValue(current);
  }, [form, originalList, match.params.id]);

  return (
    <Wrap>
      <Left products={data} reloadData={fetchData} />
      <Right>
        <Divider orientation="left" plain>导航</Divider>
        <Form colon={false} form={form} autoComplete="off">
          <Form.Item label="名称" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="是否显示" name="show" rules={[{ required: true }]} valuePropName="checked" >
            <Switch  />
          </Form.Item>
          <Form.Item label=" ">
            <Space>
              <Button type="primary" onClick={saveItem}>
                保存
              </Button>
              <Button onClick={addSubItem}>新增子级</Button>
              <Button danger onClick={remove}>
                删除
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Divider plain orientation="left">内容</Divider>
        <ProductPage refId={match.params.id} noCover />
      </Right>
    </Wrap>
  );
};
