import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import App from './App';
import Login from './views/login'
import ErrorPage from './views/error'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/error" component={ErrorPage} ></Route>
      <Route path="/login" component={Login} ></Route>
      <Route path="/" component={App} ></Route>
    </Switch>
  </BrowserRouter>
  ,
  document.getElementById('root')
);
