import React from 'react';
import { Layout, ConfigProvider, locales } from 'antd';
import moment from 'moment'
import { Switch, Route, NavLink } from 'react-router-dom'

import HomeData from './views/home'
import CategoryPage from './views/category'
import ArticlePage from './views/category/ProductPage'
import ArticleList from './views/article/Articles'
import Match from './views/match'
import FormList from './views/formList'
import styled from 'styled-components'

import { ping } from './api/index'
import './App.css'

moment.locale('zh-cn');

const { Content, Sider } = Layout;

const ArticlePageContent = styled.div`
  padding:32px;
`
function App({ history, location }) {

  React.useEffect(() => {
    ping().catch(({ response }) => {
      if (response.status === 401) {
        history.replace('/login')
        return
      }
      if (response.status >= 500) {
        history.replace('/error?status=' + response.status)
        return
      }
    })
  }, [history])

  return (
    <ConfigProvider locale={locales.zh_CN}>
      <Layout>
        <Sider
          className="page-sider"
          theme="light"
        >
          <ul>
            <li>
              <NavLink to="/home" className="sider-link">
                首页设置
            </NavLink>
            </li>
            <li>
              <NavLink to="/navigation" className="sider-link">
                产品解决方案导航栏
            </NavLink>
            </li>
            <li>
              <NavLink to="/form-list" className="sider-link">
                表单列表
            </NavLink>
            </li>
            <li>
              <span className="sider-link">人力资源比赛</span>
              <ul>
                <li>
                  <NavLink to="/match/college" className="sider-link">
                    专科
                </NavLink>
                </li>
                <li>
                  <NavLink to="/match/undergraduates" className="sider-link">
                    本科
                </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <span className="sider-link">关于踏瑞</span>
              <ul>
                <li>
                  <NavLink to="/about-us" className="sider-link">
                    公司概况
                </NavLink>
                </li>
                <li>
                  <NavLink to="/corporate-culture" className="sider-link">
                    企业文化
                </NavLink>
                </li>
                <li>
                  <NavLink to="/join-us" className="sider-link">
                    加入踏瑞
                </NavLink>
                </li>
                <li>
                  <NavLink to="/activity" className="sider-link">
                    活动&资讯
                  </NavLink>
                </li>
                
              </ul>
            </li>
            <li>
              <span className="sider-link">1+X</span>
              <ul>
                <li>
                  <NavLink to="/1x-index" className="sider-link">
                    证书介绍
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/1x-teacher-training" className="sider-link">
                    师资培训
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/1x-apply" className="sider-link">
                    试点院校预申请
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/1x-information" className="sider-link">
                    资料下载
                  </NavLink>
                  <NavLink to="/1x-sites" className="sider-link">
                  考核站点
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>

        </Sider >
        <Layout className="site-layout" style={{ marginLeft: 200 }}>
          <Content style={{ overflow: 'initial' }}>
            <div className="site-layout-background" style={{ margin: 24, minHeight: 'calc(100vh - 48px)' }}>
              <Switch>
                <Route exact path="/home" component={HomeData} />

                <Route path="/navigation/:id?" component={CategoryPage} />

                <Route path="/about-us" render={() => <ArticlePageContent><ArticlePage refId="000000000000000000000001" /></ArticlePageContent>} />

                <Route path="/corporate-culture" render={() => <ArticlePageContent><ArticlePage refId="000000000000000000000003" /></ArticlePageContent>} />

                <Route path="/join-us" render={() => <ArticlePageContent><ArticlePage refId="000000000000000000000002" /></ArticlePageContent>} />

                <Route path="/activity" render={({match})=> <ArticleList match={match} />} />                

                <Route path="/match/:type" component={Match} />

                <Route path="/form-list" component={FormList} />

                <Route path="/1x-index" render={() => <ArticlePageContent><ArticlePage coverRequired={false} refId="000000000000000000000007" /></ArticlePageContent>} />
                <Route path="/1x-teacher-training" render={() => <ArticlePageContent><ArticlePage coverRequired={false} refId="000000000000000000000008" /></ArticlePageContent>} />
                <Route path="/1x-apply" render={() => <ArticlePageContent><ArticlePage coverRequired={false} refId="000000000000000000000009" /></ArticlePageContent>} />
                <Route path="/1x-information" render={() => <ArticlePageContent><ArticlePage coverRequired={false} refId="000000000000000000000010" /></ArticlePageContent>} />
                <Route path="/1x-sites" render={() => <ArticlePageContent><ArticlePage coverRequired={false} refId="000000000000000000000016" /></ArticlePageContent>} />
            
              </Switch>
            </div>
          </Content>
        </Layout>
      </Layout >
    </ConfigProvider>
  );
}

export default App;
