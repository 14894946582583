import moment from 'moment'


export const getDescription = (content) => {
    let contentDiv = document.createElement("div");
    contentDiv.innerHTML = content;
    return contentDiv.innerText.substr(0, 275);
};

export const dateFormat = (val, _default = '----/--/--') => {
    return moment(val).format("YYYY-MM-DD HH:mm")

}