import React, { useEffect, useState, useCallback } from "react";
import { Switch, Route, useHistory, Link } from "react-router-dom";
import {
  Table,
  Button,
  Form,
  Spin,
  Input,
  message,
  PageHeader,
  Descriptions,
  Modal,
  Select,
} from "antd";
import useUrlState from "@ahooksjs/use-url-state";
import { getDescription, dateFormat } from "../../tools";
import Upload from "../../components/Upload";
import Editor from "../../components/Editor";
import DateTimePicker from "../../components/DateTimePicker";
import ImportContent from "../../components/ImportContent";

import {
  getArticleListByRefIds,
  getArticleById,
  createArticle,
  updateArticleById,
  deleteArticleById,
} from "../../api";

const Names = {
  "000000000000000000000004": {
    name: "活动",
    url: "/activity",
  },
  "000000000000000000000015": {
    name: "资讯",
    url: "/news",
  },
};

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/:id`} component={ArticleEditPage} />
      <Route path={match.path} render={() => <ArticleListPage />} />
    </Switch>
  );
};

const columns = [
  {
    title: "名称",
    dataIndex: "title",
    ellipsis: true,
  },

  {
    title: "发布时间",
    dataIndex: "publishDate",
    width: "10%",
    render: dateFormat,
  },
  {
    title: "类型",
    dataIndex: "refId",
    width: "10%",
    render: (refId) => Names[refId].name,
  },
  {
    title: "更新时间",
    dataIndex: "updateAt",
    width: "10%",
    render: dateFormat,
  },
  {
    title: "操作",
    width: "5%",
    render: (row) => {
      return <Link to={`/activity/${row.id}`}>编辑</Link>;
    },
  },
];

export const ArticleListPage = () => {
  const [loading, setLoading] = useState();
  const [urlState, setUrlState] = useUrlState({
    page: 1,
    size: 10,
    name: "",
  });
  const [pageModel, setPageModel] = useState({
    content: [],
    count: 0,
    currentPage: 0,
    size: 0,
  });

  const onPageChange = useCallback((page, size, searchValue) => {
    setLoading(true);
    getArticleListByRefIds(Object.keys(Names), page, size, searchValue)
      .then(({ data }) => {
        setPageModel(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    onPageChange(urlState.page, urlState.size, urlState.name);
  }, [onPageChange, urlState]);
  return (
    <div style={{ padding: 32 }}>
      <div>
        <h3>资讯&活动-列表</h3>
        <Button type="link" href={`/activity/new`}>
          新增
        </Button>
      </div>
      <div className="search-form">
        <Input.Search
          allowClear
          placeholder="搜索名称"
          style={{ width: 300 }}
          defaultValue={urlState.name}
          onSearch={(name) => setUrlState({ name,page:1 })}
        />
      </div>
      <Table
        loading={loading}
        tableLayout="fixed"
        columns={columns}
        dataSource={pageModel.content}
        rowKey={(t) => t.id}
        pagination={{
          current: pageModel.currentPage,
          pageSize: pageModel.size,
          total: pageModel.count,
          onChange: (page, size) => setUrlState({ page, size }),
        }}
      />
    </div>
  );
};

export const ArticleEditPage = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [article, setArticle] = useState({});
  const [form] = Form.useForm();
  const refId = article.refId;
  const save = async () => {
    const formData = await form.validateFields();
    if (match.params.id === "new") {
      const { data } = await createArticle({
        ...formData,
        description: formData.description || getDescription(formData.content),
      });
      message.success("保存成功！");
      history.replace(`/activity/${data.id}`);
      return;
    }

    const { data } = await updateArticleById(match.params.id, {
      ...article,
      ...formData,
      description: formData.description || getDescription(formData.content),
    });
    form.setFieldsValue(data);
    message.success("保存成功！");
  };

  const deleteArticle = () => {
    Modal.confirm({
      title: "提示！",
      content: "确实要删除此条活动吗？",
      onOk: () => {
        return deleteArticleById(match.params.id)
          .then(() => {
            message.success("删除成功！");
            history.push("/activity");
          })
          .catch(() => {
            message.error("删除失败，请刷新后重试！");
            return Promise.reject();
          });
      },
    });
  };

  useEffect(() => {
    if (!match.params.id || match.params.id === "new") return;
    setLoading(true);
    getArticleById(match.params.id)
      .then(({ data }) => {
        if (data) {
          form.setFieldsValue(data);
          setArticle(data);
        } else {
          setArticle({});
          form.resetFields();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form, match.params.id]);

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => history.go(-1)}
        title={`${Names[refId]?.name}详情`}
        extra={[
          <Button
            key="2"
            onClick={deleteArticle}
            disabled={match.params.id === "new"}
          >
            删除
          </Button>,
          <Button key="1" onClick={save} type="primary">
            保存
          </Button>,
          <ImportContent
            onOk={(html) => form.setFieldsValue({ content: html })}
          />,
        ]}
      >
        <Descriptions size="small" column={2}>
          <Descriptions.Item label="创建时间">
            {dateFormat(article.createAt)}
          </Descriptions.Item>
          <Descriptions.Item label="更新时间">
            {dateFormat(article.updateAt)}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <div style={{ padding: "0 32px 32px 32px" }}>
        <Spin spinning={loading} delay={500}>
          <Form form={form} autoComplete="off" scrollToFirstError={true}>
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入标题！" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="发布日期"
              name="publishDate"
              rules={[{ required: true, message: "请选择日期！" }]}
            >
              <DateTimePicker
                showTime
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              label="类型"
              name="refId"
              rules={[{ required: true, message: "请选择类型" }]}
            >
              <Select
                options={[
                  { label: "咨询", value: "000000000000000000000015" },
                  { label: "活动", value: "000000000000000000000004" },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 24 }}
              label="概要"
              name="description"
              help={
                <Button
                  type="dashed"
                  size="small"
                  onClick={() => {
                    form.setFieldsValue({
                      description: getDescription(
                        form.getFieldValue("content")
                      ),
                    });
                  }}
                >
                  从正文提取概要
                </Button>
              }
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="封面" extra="建议尺寸 825*404" name="cover">
              <Upload />
            </Form.Item>
            <Form.Item
              label="正文"
              name="content"
              rules={[{ required: true, message: "请输入正文！" }]}
            >
              <Editor />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
};
