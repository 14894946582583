import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, message, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Upload from "../../components/Upload";

import { getMatchCarouseByLevel, saveMatchCarouse } from "../../api";

const MatchCarouse = ({ type }) => {
  const [carouselData, setCarouselData] = useState({});
  const [form] = Form.useForm();
  useEffect(() => {
    getMatchCarouseByLevel(type).then(({ data }) => {
      setCarouselData(data);
      form.setFieldsValue(data);
    });
  }, [form, type]);

  const onFinish = (value) => {
    if (value.items.length < 1) {
      message.warn("请添加至少一个轮播图");
      return;
    }

    saveMatchCarouse({ ...value, level: type }).then(() => {
      message.success("设置成功！");
    });
  };

  const removeItem = (onOk) => {
    Modal.confirm({
      title: "提示",
      content: "确认要删除此项吗",
      onOk,
    });
  };
  return (
    <Form
      scrollToFirstError={true}
      form={form}
      onFinish={onFinish}
      className="carousel-form"
      autoComplete="off"
      layout="vertical"
      initialValues={carouselData}
    >
      <Form.Item>
        <Button type="primary" htmlType="submit">
          保存
        </Button>
      </Form.Item>
      <Form.Item hidden name="id">
            <Input />
      </Form.Item>
      <Form.Item hidden name="createAt">
            <Input />
      </Form.Item>
      <Form.Item hidden name="updateAt">
            <Input />
      </Form.Item>
      <Form.List name="items">
        {(fields, { add, remove, move }) => {
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <div
                    key={field.key}
                    style={{
                      margin: "8px 0",
                      padding: 16,
                      border: "1px solid #d9d9d9",
                      borderRadius: 2,
                    }}
                  >
                    <Space>
                      <Button
                        onClick={() => {
                          add({}, index + 1);
                        }}
                      >
                        新增
                      </Button>
                      <Button
                        onClick={() => {
                          removeItem(() => {
                            remove(field.name);
                          });
                        }}
                      >
                        删除
                      </Button>
                    </Space>
                    <Form.Item
                      label="轮播图"
                      name={[field.name, "cover"]}
                      fieldKey={[field.fieldKey, "cover"]}
                      rules={[{ required: true, message: "请上传图片" }]}
                      extra={"点击预览图片"}
                    >
                      <Upload
                        height={200}
                        width={613}
                        style={{ outline: "1px dashed" }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="描述"
                      name={[field.name, "title"]}
                      fieldKey={[field.fieldKey, "title"]}
                      rules={[{ whitespace: true, message: "请输入有效描述" }]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                      label="链接至"
                      name={[field.name, "link"]}
                      fieldKey={[field.fieldKey, "link"]}
                      rules={[
                        { required: true, message: "请输入链接" },
                        {
                          pattern: /^(http|\/|#)/,
                          message: "链接必须以 /、http开头或者是#",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add({});
                  }}
                  block
                >
                  <PlusOutlined /> 新增一个轮播图
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default MatchCarouse;
