import { useEffect, useState, useCallback } from "react";
import { getProduct } from '../api/index'



export const useCategory = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [originalList, setOriginalList] = useState([]);
    const fetchData = useCallback(() => {
        setLoading(true)
        return getProduct().then(({ data }) => {
            for (let item of data) {
                item.children = data.filter((it) => item.id === it.parentId);
            }
            setOriginalList(data)
            const _data = data.filter((item) => !item.parentId);
            setData(_data);
        }).finally(() => {
            setLoading(false)
        });
    }, []);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return { data, fetchData, loading,originalList }
}