import React from "react";
import { DatePicker } from "antd";

export default ({ value, onChange, ...restProps }) => {
  const _onChange = (val) => {
    onChange(val.toDate());
  };
  return (
    <DatePicker
      value={!!value ? window.moment(value) : null}
      onChange={_onChange}
      {...restProps}
    />
  );
};
